<template>
  <v-card id="to-print">
    <v-card-title class="header-print" v-if="showHeader">
      <v-container class="pa-0">
        <v-row justify="space-between">
          <img src="@/assets/images/logo-dark.svg" alt="" />
          <v-subheader class="text-transform-capitalize">
            {{ getDate }}
          </v-subheader>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container class="my-5 no-page-break ">
        <v-row class="mb-5" justify="space-between" align="center">
          <v-col cols="auto" class="pr-0">
            <h2 class="font-weight-medium">
              {{ title }}
            </h2>
          </v-col>

          <div class="mt-1 d-print-none aling-center d-flex">
            <v-btn color="primary" @click="printPage">
              <v-icon left>mdi-printer</v-icon>
              {{ $t("cabinet.print") }}
            </v-btn>
          </div>
        </v-row>
        <slot></slot>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    showHeader: {
      require: false,
      default: true
    },
    title: {
      require: true
    },
    visible: {
      require: true
    }
  },
  methods: {
    printPage() {
      window.print();
    }
  },
  computed: {
    getDate() {
      return moment().format("DD.MM.Y");
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    }
  }
};
</script>

<style>
.header-print {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
  background: #e3e3e357;
}

@media print {
  .header-print {
    padding: 20px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    background: #e3e3e357;;
  }

  body {
    margin: 0;
    padding: 0;
  }
  .no-page-break {
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .v-dialog__content.v-dialog__content--active {
    position: inherit;
  }
  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }
  .container {
    padding: 10px 20px !important;
  }
  #to-print {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }
}
</style>
