<template>
  <VDialog
    v-model="visibility"
    @click:outside="showConfirmDialog"
    :persistent="isChanged"
    max-width="600px"
    content-class="cross__dialog"
    scrollable
    :transition="$dialogTransition"
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{'Змінити статус'}}</span>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <div class="text-center" v-if="loading">
            <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow>
              <VCol cols="12">
                <VTextField
                  outlined
                  dense
                  :label="$t('form.name')"
                  v-model="inputDepot.name"
                  required
                  :error-messages="titleErrors"
                  :readonly="!permissions.can_edit_depot"
                  hide-details="auto"
                  color="success"
                  @keypress="validationForName($event)"
                  @paste="validationForName($event)"
                ></VTextField>
              </VCol>
              <VCol cols="12">
                <v-autocomplete
                  :label="$t('addresses.adressesTitle')"
                  :items="addresses"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="$t('table.noData')"
                  :value="inputDepot.uuid_address"
                  v-model="inputDepot.uuid_address"
                  outlined
                  :readonly="!permissions.can_edit_depot"
                  dense
                  hide-details="auto"
                  :error-messages="AdressError"
                  small-chips
                  color="success"
                  item-color="success"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                />
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions v-if="permissions.can_edit_depot">
        <VRow class="my-0 py-0 container">
          <VCol cols="6" class="py-0 my-0">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              dark
              @click="visibility = false"
              block
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol cols="6" class="py-0 my-0">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              @click="onUpdate"
              block
              :disabled="!isChanged"
            >
             {{ $t('btn.update') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import notifications from "../../mixins/notifications";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import user from "../../mixins/user";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import addressService from "../../services/request/address/addressService";
import { validationForName, validationForTitle} from "@/mixins/helpers";

export default {
  name: "UpdateDepotDialog",
  data: () => ({
    loading: false,
    sectors: [],
    addresses: [],
    inputDepot: {},
    visibleConfirm: false,
    isChanged: false
  }),
  validations: {
    inputDepot: {
      name: { required },
      uuid_address: { required }
    }
  },
  components: {
    ConfirmLeftDialog
  },
  watch: {
    inputDepot: {
      deep: true,
      handler(e) {
        // orderDialog(e);
        this.isChanged = !_.isEqual(this.depot, this.inputDepot);
      }
    }
  },
  mixins: [user, language, notifications, validationMixin],
  methods: {
    validationForTitle,
    validationForName,
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("uuid", this.inputDepot.uuid);

          const changedData = this.$getChangedData(this.inputDepot, this.depot);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });

          await depotService.updateDepot(formData);
          this.loading = false;
          this.setSuccessNotification(this.$t('storages.storage_was_updated'));
          EventBus.$emit("depot-updated");
          this.visibility = false;
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    }
  },
  props: {
    visible: {
      required: true
    },
    depot: {
      required: true
    }
  },
  async mounted() {
    try {
      // orderDialog('this.depot', this.depot);
      this.inputDepot = { ...this.depot };
      this.addresses = await addressService.getAddressesList();
      // orderDialog('addresses', this.addresses);
      // orderDialog('this.inputDepot', this.inputDepot);
      // const test = this.addresses.filter((item) => item.uuid_ref_warehouse === this.inputDepot.uuid_address);
      // // orderDialog('test', test);
      this.sectors = await depotService.getSectorsList({
        uuid_deport: this.depot.uuid
      });
    } catch (e) {
      console.log(e)
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.inputDepot.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDepot.name.required &&
        errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    AdressError() {
      const errors = [];
      if (!this.$v.inputDepot.uuid_address.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputDepot.uuid_address.required &&
        errors.push(this.$t('form.errors.AddressesRequired'));
      return errors;
    }
  }
};
</script>

<style scoped></style>
