<template>
  <NsCodesComponent />
</template>

<script>
import store from '@/store';
import NsCodesComponent from "@/components/nsCodes/NsCodesComponent.vue";

export default {
  name: 'Sectors',
  components: {
    NsCodesComponent,
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['auth/getPermissions'].can_view_ns_codes_page) {
      next();
      return;
    }
    next(from);
  },
};
</script>

<style scoped>

</style>
