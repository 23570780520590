<template>
  <tr>
    <td class=" text-center">
      <v-icon :size="isLarge ? '40' : '30'"> mdi-barcode </v-icon>
    </td>
    <td>{{ ns_code.product_info.name || "--" }}</td>
    <td>{{ ns_code.product_info.option_name || "--" }}</td>
    <td
      v-if="ns_code.ns_range_from"
      @click="copyToClipboard(ns_code.ns_range_from)"
      class="copyTxt"
    >
      {{ ns_code.ns_range_from || "--" }}
    </td>
    <td v-if="ns_code.ns_range_to" @click="copyToClipboard(ns_code.ns_range_to)" class="copyTxt">
      {{ ns_code.ns_range_to || "--" }}
    </td>
    <td class="text-center">{{ nsRangeAmount() || "--" }}</td>
    <td v-if="ns_code.uuid_delivery == '00000000-0000-0000-0000-000000000000'"></td>
    <td
      v-if="ns_code.uuid_delivery != '00000000-0000-0000-0000-000000000000'"
      @click="openIncome()"
      class="copyTxt d-flex align-center justify-center"
    >
      <package-down-icon />
    </td>
    <td class="text-center">
      <v-btn
        class="btn-edit"
        :class="{ sm: !isLarge }"
        color="info"
        @click="onHandlePrint('open', ns_code)"
        ><v-icon :small="!isLarge">mdi-printer</v-icon></v-btn
      >
    </td>
    <UpdateNsCodeDialog
      v-if="updateDialog"
      :depot="ns_code"
      :visible="updateDialog"
      @close="updateDialog = false"
    />
    <EditSupplyInDialog
      v-if="isOpenIncome"
      :visible="isOpenIncome"
      @close="isOpenIncome = false"
      :supply="income"
    />
  </tr>
</template>

<script>
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";
import UpdateNsCodeDialog from "@/components/nsCodes/UpdateNsCodeDialog.vue";
import notifications from "../../mixins/notifications";
import EditSupplyInDialog from "@/components/supply/EditSupplyInDialog.vue";
import PrintDialogWrapper from "../dialog/printDialogWrapper.vue";
import NsCodeRanges from "../../views/depots/NsCodeRanges.vue";

export default {
  name: "NsCodesRow",
  mixins: [user, SizeUi, notifications],
  components: {
    EditSupplyInDialog,
    UpdateNsCodeDialog,
    PrintDialogWrapper,
    NsCodeRanges
  },
  props: {
    ns_code: {
      required: true
    },
    index: {
      required: true
    },
    itemsIN: {
      required: true
    }
  },
  data: () => ({
    updateDialog: false,
    deleteDialog: false,
    status: "",
    isOpenIncome: false,
    income: [],
    printHandle: {
      visible: false,
      item: {}
    }
  }),
  methods: {
    onHandlePrint(action, item) {
      if (action === "open") {
        const route = this.$router.resolve({
          name: "ns-codes_ranges",
          params: {
            ns_range_from: item.ns_range_from,
            ns_range_to: item.ns_range_to
          }
        });
        window.open(route.href, "_blank");
        this.printHandle.item = item;
      } else if (action === "close") {
        this.printHandle.visible = false;
        this.printHandle.item = {};
      }
    },
    openIncome() {
      if (this.ns_code.uuid_delivery != "00000000-0000-0000-0000-000000000000") {
        this.itemsIN.forEach(item => {
          if (item.uuid === this.ns_code.uuid_delivery) {
            this.income = item;
          }
        });
        this.isOpenIncome = true;
      }
    },
    onEdit(e) {
      this.updateDialog = true;
      this.$emit("toggleRow", e);
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    nsRangeAmount() {
      return Number(this.ns_code.ns_range_to) - Number(this.ns_code.ns_range_from) + 1;
    }
  },
  computed: {
    background() {
      if (this.index % 2 === 0) {
        return "#F8F8F8";
      }
      return "white";
    }
  }
};
</script>

<style scoped lang="scss">
.td {
  height: 40px;
  padding: 4px 16px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  color: #676767;
  text-align: left;
  border-left: 1px solid #f2f2f2;
  &:last-child {
    padding: 4px;
    border-right: 1px solid #f2f2f2;
  }
}
.ico {
  padding: 0 !important;
}
</style>
