<template>
  <NsCodesList />
</template>

<script>
import lang from '@/mixins/language';
import NsCodesList from './NsCodesList.vue';

export default {
  name: 'NsCodesComponent',
  mixins: [lang],
  components: {
    NsCodesList,
  },
  data: () => ({
    tab: 0,
  }),
};
</script>

<style scoped>

</style>
