<template>
  <v-row>
    <v-col v-for="(item, index) in allNsCodes" :key="index" class="barCodeSvg no-page-break">
      <barcode :value="item" tag="svg"></barcode>
    </v-col>
  </v-row>
</template>

<script>
export default {
  mounted() {
    this.initData();
  },
  data: () => ({
    item: {
      ns_range_from: 0,
      ns_range_to: 0
    }
  }),
  methods: {
    initData() {
      // eslint-disable-next-line camelcase
      const { ns_range_from, ns_range_to } = this.$route.params;
      console.log(this.$route)
      this.item = {
        ns_range_from,
        ns_range_to
      };
    }
  },
  computed: {
    allNsCodes() {
      const startValue = Number(this.item.ns_range_from);
      const endValue = Number(this.item.ns_range_to);
      return Array.from({ length: endValue - startValue + 1 }, (_, index) => startValue + index);
    }
  }
};
</script>

<style lang="scss" scoped>
.barCodeSvg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease;
  height: 112px;
  margin-top: -9px;

  &:after {
    content: "TTH";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #000;
    opacity: 0;
    transition: all 0.3s ease;
  }

  svg {
    height: 100%;
    width: auto;
    // margin: 0 auto;
    display: block;
    opacity: 1;
  }
}
</style>
